/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment ArticleInfo on Article {\n    heading\n    author\n    slug\n    abstract\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n": types.ArticleInfoFragmentDoc,
    "\n  query getArticleCategories {\n    articleCategories(article_status: \"Published\") {\n      name\n      slug\n      articles_count\n    }\n  }\n": types.GetArticleCategoriesDocument,
    "\n  query getArticle($slug: String) {\n    article(slug: $slug) {\n      heading\n      sub_heading\n      author\n      slug\n      abstract\n      article\n      status\n      media_collection {\n        name\n        media {\n          square\n          banner\n        }\n      }\n    }\n  }\n": types.GetArticleDocument,
    "\n  query getArticlesByCategories($categories: [String!], $page: Int!, $itemsPerPage: Int!) {\n    articlesPagination(\n      status: \"Published\"\n      categories: $categories\n      page: $page\n      itemsPerPage: $itemsPerPage\n    ) {\n      data {\n        ...ArticleInfo\n      }\n      total\n      has_more_pages\n      last_page\n      current_page\n    }\n  }\n": types.GetArticlesByCategoriesDocument,
    "\n  fragment ComponentTypeInfo on ComponentType {\n    name\n    status\n    base_path\n    components {\n      ...ComponentInfo\n    }\n  }\n": types.ComponentTypeInfoFragmentDoc,
    "\n  fragment ComponentInfo on Component {\n    name\n    slug\n    status\n    contents {\n      ...ComponentContentInfo\n    }\n  }\n": types.ComponentInfoFragmentDoc,
    "\n  fragment ComponentContentInfoWithMedia on ComponentContent {\n    ...ComponentContentInfo\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n": types.ComponentContentInfoWithMediaFragmentDoc,
    "\n  fragment ComponentContentInfo on ComponentContent {\n    name\n    type\n    value\n  }\n": types.ComponentContentInfoFragmentDoc,
    "\n  query getfaqs {\n    websiteFaqCategory {\n      name\n      sort\n      website_faqs {\n        question\n        answer\n        slug\n        sort\n      }\n    }\n  }\n": types.GetfaqsDocument,
    "\n  query getFaqDetail($faq_slug: String!) {\n    websiteFaq(faq_slug: $faq_slug) {\n      question\n      answer\n    }\n  }\n": types.GetFaqDetailDocument,
    "\n  fragment MediaCollectionInfo on MediaCollection {\n    name\n    description\n    media {\n      ...MediaInfo\n    }\n  }\n": types.MediaCollectionInfoFragmentDoc,
    "\n  fragment MediaInfo on Media {\n    default\n    thumbnail\n    banner\n    square\n    rectangle\n    original_cropped\n    portrait\n    alt_tag\n    approved\n  }\n": types.MediaInfoFragmentDoc,
    "\n  fragment SquareMediaCollection on MediaCollection {\n    name\n    media {\n      original_cropped\n      square\n    }\n  }\n": types.SquareMediaCollectionFragmentDoc,
    "\n  fragment BannerMediaCollection on MediaCollection {\n    name\n    media {\n      original_cropped\n      banner\n    }\n  }\n": types.BannerMediaCollectionFragmentDoc,
    "\n  fragment GalleryMedia on OriginMedia {\n    id\n    name\n    description\n    url\n    categories\n  }\n": types.GalleryMediaFragmentDoc,
    "\n  query SlugGalleryLists($name: String!, $slug: String!) {\n    tribe(name: $name, slug: $slug) {\n      name\n      all_media(star: true, count: 300, order: \"media.created_at\", desc: true) {\n        ...GalleryMedia\n      }\n    }\n  }\n": types.SlugGalleryListsDocument,
    "\n  fragment OurWorkInfo on OurWork {\n    heading\n    sub_heading\n    meta_title\n    meta_description\n    author\n    slug\n    status\n    article\n    excerpt\n    published_at\n    client_name\n    client_url\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n": types.OurWorkInfoFragmentDoc,
    "\n  query getOurWorks($tribeSlug: String!, $page: Int!, $itemsPerPage: Int!) {\n    ourWorks(tribe_slug: $tribeSlug, page: $page, itemsPerPage: $itemsPerPage) {\n      data {\n        ...OurWorkInfo\n      }\n      total\n      has_more_pages\n      last_page\n      current_page\n    }\n  }\n": types.GetOurWorksDocument,
    "\n  query getOurWork($slug: String!, $tribeSlug: String!) {\n    ourWork(slug: $slug, tribe_slug: $tribeSlug) {\n      ...OurWorkInfo\n    }\n  }\n": types.GetOurWorkDocument,
    "\n  fragment ProductCategoryInfo on ProductCategory {\n    name\n    menu_label\n    status\n    slug\n    heading\n    sub_heading\n    caption\n    path\n    description\n    meta\n    page_heading\n    page_sub_heading\n    meta_title\n    meta_description\n  }\n": types.ProductCategoryInfoFragmentDoc,
    "\n  fragment ProductInProductCategoryInfo on Product {\n    name\n    menu_label\n    slug\n    description\n    media_collection(size: \"square\") {\n      ...MediaCollectionInfo\n    }\n  }\n": types.ProductInProductCategoryInfoFragmentDoc,
    "\n  query getProductCategory($slug: String!) {\n    productCategory(slug: $slug) {\n      ...ProductCategoryInfo\n      media_collection {\n        ...BannerMediaCollection\n      }\n      children {\n        name\n        menu_label\n        slug\n        media_collection(size: \"square\") {\n          ...SquareMediaCollection\n        }\n      }\n      products {\n        ...ProductInProductCategoryInfo\n      }\n      tribe_custom_images {\n        ...BannerMediaCollection\n      }\n    }\n  }\n": types.GetProductCategoryDocument,
    "\n  query getTribeProductCategory($slug: String!, $tribeSlug: String!) {\n    productCategory(slug: $slug, tribe_slug: $tribeSlug) {\n      ...ProductCategoryInfo\n      media_collection {\n        ...BannerMediaCollection\n      }\n      tribe_custom_images(images_tribe_slug: $tribeSlug) {\n        ...BannerMediaCollection\n      }\n      children {\n        name\n        menu_label\n        slug\n        media_collection(size: \"square\") {\n          ...SquareMediaCollection\n        }\n      }\n      products {\n        ...ProductInProductCategoryInfo\n      }\n      tribe_product_categories(tribe_slug: $tribeSlug) {\n        id\n        tribe_id\n        data\n      }\n    }\n  }\n": types.GetTribeProductCategoryDocument,
    "\n  query getProductCategoryPDF($slug: String!) {\n    productCategory(slug: $slug) {\n      name\n      componentTypes(name: \"PDF Downloads\") {\n        ...ComponentTypeInfo\n        components {\n          ...ComponentInfo\n          contents {\n            ...ComponentContentInfoWithMedia\n          }\n        }\n      }\n    }\n  }\n": types.GetProductCategoryPdfDocument,
    "\n  query getProductsFromProductCategories {\n    categories {\n      name\n      menu_label\n      slug\n      products {\n        ...ProductInProductCategoryInfo\n      }\n    }\n  }\n": types.GetProductsFromProductCategoriesDocument,
    "\n  fragment ProductInfo on Product {\n    id\n    name\n    menu_label\n    status\n    slug\n    heading\n    sub_heading\n    caption\n    description\n    meta\n    page_heading\n    page_sub_heading\n    product_type {\n      ...ProductTypeInfo\n    }\n    product_categories {\n      ...ProductCategoryInProductInfo\n    }\n    media_collection {\n      ...MediaCollectionInfo\n    }\n    tribe_custom_images(images_tribe_slug: $tribeSlug) {\n      ...MediaCollectionInfo\n    }\n    product_custom_data(tribe_slug: $tribeSlug, website_id: $websiteId) {\n      ...ProductCustomDataInfo\n    }\n    componentTypes(name: $componentTypeName) {\n      ...ComponentTypeInfo\n    }\n  }\n": types.ProductInfoFragmentDoc,
    "\n  fragment productComponentTypeInfo on Component {\n    name\n    slug\n    contents {\n      name\n      type\n      value\n      media_collection {\n        name\n        media {\n          rectangle\n          banner\n        }\n      }\n    }\n    products {\n      name\n      slug\n    }\n  }\n": types.ProductComponentTypeInfoFragmentDoc,
    "\n  fragment ProductTypeInfo on ProductType {\n    name\n  }\n": types.ProductTypeInfoFragmentDoc,
    "\n  fragment ProductCategoryInProductInfo on ProductCategory {\n    name\n    slug\n  }\n": types.ProductCategoryInProductInfoFragmentDoc,
    "\n  fragment ProductCustomDataInfo on ProductCustomData {\n    name\n    value\n    type\n  }\n": types.ProductCustomDataInfoFragmentDoc,
    "\n  query getProduct(\n    $slug: String!\n    $websiteId: Int!\n    $componentTypeName: [String]\n    $tribeSlug: String\n  ) {\n    product(slug: $slug, tribe_slug: $tribeSlug) {\n      ...ProductInfo\n      product_related_products {\n        name\n        slug\n        media_collection {\n          name\n          media {\n            square\n            banner\n          }\n        }\n      }\n    }\n  }\n": types.GetProductDocument,
    "\n  query getProductComponent($componentType: String!) {\n    productComponents(componentType: $componentType) {\n      ...productComponentTypeInfo\n    }\n  }\n": types.GetProductComponentDocument,
    "\n  query getProductComponentDetail($componentType: String!, $slug: String!) {\n    productComponents(componentType: $componentType, slug: $slug) {\n      ...productComponentTypeInfo\n    }\n  }\n": types.GetProductComponentDetailDocument,
    "\n  fragment PromotionWebsiteContentInfo on PromotionWebsiteContent {\n    name\n    type\n    value\n    products\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n": types.PromotionWebsiteContentInfoFragmentDoc,
    "\n  fragment PromotionInfo on Promotion {\n    name\n    slug\n    type\n    status\n    fields\n    start_date\n    end_date\n    website_content {\n      ...PromotionWebsiteContentInfo\n    }\n  }\n": types.PromotionInfoFragmentDoc,
    "\n  fragment PromotionBannerInfo on PromotionBanner {\n    name\n    value\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n": types.PromotionBannerInfoFragmentDoc,
    "\n  query getLivePromotionBanner {\n    promotionBanner {\n      name\n      start_date\n      end_date\n      banner {\n        ...PromotionBannerInfo\n      }\n    }\n  }\n": types.GetLivePromotionBannerDocument,
    "\n  query getPromotion($slug: String!) {\n    promotion(slug: $slug) {\n      ...PromotionInfo\n    }\n  }\n": types.GetPromotionDocument,
    "\n  query getPromotionVueComponent($slug: String!) {\n    promotion(slug: $slug) {\n      name\n      slug\n      vue_component\n    }\n  }\n": types.GetPromotionVueComponentDocument,
    "\n    query getRegionPolygons($slug: String, $countryISO: String, $regionType: String) {\n      regions(slug: $slug, country_iso: $countryISO, type: $regionType) {\n        name\n        country_iso\n        name_abbreviated\n        latitude\n        longitude\n        zoom\n        polygons\n      }\n    }\n  ": types.GetRegionPolygonsDocument,
    "\n  fragment BaseTribeInfo on Tribe {\n    name\n    slug\n    heading\n    sub_heading\n    country\n    postal_code\n    state\n    state_abbreviated\n    locality\n    address_1\n    address_2\n    latitude\n    longitude\n    main_telephone\n    organic_number\n    paid_number\n    telephone_url_triggers\n    public_email\n    google_place_id\n    social_google_url\n    social_twitter_url\n    social_facebook_url\n    social_linkedin_url\n    social_instagram_url\n    social_youtube_url\n    social_pinterest_url\n    facebook_review_link\n    google_review_url\n    yelp_review_url\n    meta_description\n    meta_title\n    whats_app_number\n    facebook_pixel_id\n  }\n": types.BaseTribeInfoFragmentDoc,
    "\n  fragment TribeInfo on Tribe {\n    ...BaseTribeInfo\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n": types.TribeInfoFragmentDoc,
    "\n  fragment TribeTypeInfo on TribeType {\n    name\n  }\n": types.TribeTypeInfoFragmentDoc,
    "\n  fragment TribeContentInfo on TribeContent {\n    name\n    value\n    type\n  }\n": types.TribeContentInfoFragmentDoc,
    "\n  fragment TribeMenuInfo on TribeMenu {\n    name\n    data\n  }\n": types.TribeMenuInfoFragmentDoc,
    "\n  fragment TribeLicenseInfo on Tribe {\n    ...TribeInfo\n    contents(name: \"Licenses\") {\n      ...TribeContentInfo\n    }\n  }\n": types.TribeLicenseInfoFragmentDoc,
    "\n  fragment TribeTeamMembersInfo on Tribe {\n    name\n    introduction_team\n    teamMembers(active: true) {\n      first_name\n      last_name\n      about\n      role\n      avatar\n      email\n      show_on_website\n      mobile_e164\n      email_public\n      mobile_public\n      telephone_public\n      telephone_number\n    }\n    contents {\n      ...TribeContentInfo\n      media_collection {\n        ...MediaCollectionInfo\n      }\n    }\n  }\n": types.TribeTeamMembersInfoFragmentDoc,
    "\n  fragment TribeContactInfo on Tribe {\n    name\n    contents(names: [\"Contact Page Message\"]) {\n      ...TribeContentInfo\n    }\n  }\n": types.TribeContactInfoFragmentDoc,
    "\n  fragment TribeOpeningHoursInfo on Tribe {\n    show_opening_hours\n    tribe_opening_hours\n    opening_hours_message\n  }\n": types.TribeOpeningHoursInfoFragmentDoc,
    "\n  fragment TribeHomePageInfo on Tribe {\n    name\n    page_heading\n    page_sub_heading\n    introduction_bold\n    introduction\n    media_collection {\n      ...MediaCollectionInfo\n    }\n    contents(names: [\"Accolades\", \"Second Phone Number\", \"Homepage Video\"]) {\n      ...TribeContentInfo\n      media_collection {\n        ...MediaCollectionInfo\n      }\n    }\n  }\n": types.TribeHomePageInfoFragmentDoc,
    "\n  fragment TribeHomePageVideoInfo on Tribe {\n    name\n    contents(names: [\"Homepage Video\"]) {\n      ...TribeContentInfo\n    }\n  }\n": types.TribeHomePageVideoInfoFragmentDoc,
    "\n  fragment TribeReviewContentInfo on Tribe {\n    name\n    contents(names: [\"Review Caption\"]) {\n      ...TribeContentInfo\n    }\n  }\n": types.TribeReviewContentInfoFragmentDoc,
    "\n  fragment TribeCustomComponentInfo on TribeComponent {\n    name\n    slug\n    contents {\n      value\n      name\n      type\n    }\n  }\n": types.TribeCustomComponentInfoFragmentDoc,
    "\n  fragment TribeCustomComponentPageInfo on Tribe {\n    component_types(componentTypeName: $name) {\n      name\n      status\n      components(slug: $componentSlug) {\n        ...TribeCustomComponentInfo\n      }\n    }\n  }\n": types.TribeCustomComponentPageInfoFragmentDoc,
    "\n  fragment TribeCustomComponentPagesInfo on Tribe {\n    component_types(componentTypeName: $name) {\n      name\n      status\n      components {\n        name\n        slug\n      }\n    }\n  }\n": types.TribeCustomComponentPagesInfoFragmentDoc,
    "\n  query getTribes($tribeType: String, $order: [String]) {\n    tribes(name: $tribeType, orderBy: $order) {\n      ...TribeInfo\n    }\n  }\n": types.GetTribesDocument,
    "\n  query getTribePromotionBanner($slug: String!, $status: String) {\n    tribe(slug: $slug, status: $status) {\n      contents(names: [\"Promotion Banner\"]) {\n        ...TribeContentInfo\n      }\n    }\n  }\n": types.GetTribePromotionBannerDocument,
    "\n  query getTribe($slug: String!, $status: String) {\n    tribe(slug: $slug, status: $status) {\n      ...TribeInfo\n      media_collection {\n        ...MediaCollectionInfo\n      }\n    }\n  }\n": types.GetTribeDocument,
    "\n  query getTribeMenus($slug: String!, $status: String, $tribeType: String, $tribeMenus: [String]) {\n    tribe(slug: $slug, status: $status) {\n      tribe_menus(tribe_type: $tribeType, menus: $tribeMenus) {\n        ...TribeMenuInfo\n      }\n    }\n  }\n": types.GetTribeMenusDocument,
    "\n  query searchStores(\n    $tribe_type: String!\n    $address: String!\n    $lat: Float!\n    $lng: Float!\n    $radius: Float!\n  ) {\n    searchStores(\n      tribe_type: $tribe_type\n      address: $address\n      lat: $lat\n      lng: $lng\n      radius: $radius\n    ) {\n      tribe_types {\n        ...TribeTypeInfo\n      }\n      contents(name: \"External URL\") {\n        ...TribeContentInfo\n      }\n      ...BaseTribeInfo\n    }\n  }\n": types.SearchStoresDocument,
    "\n  query getTribeLicense($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeLicenseInfo\n    }\n  }\n": types.GetTribeLicenseDocument,
    "\n  query getTribeTeamMembers($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeTeamMembersInfo\n    }\n  }\n": types.GetTribeTeamMembersDocument,
    "\n  query getTribeContact($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeContactInfo\n    }\n  }\n": types.GetTribeContactDocument,
    "\n  query getTribeOpeningHours($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeOpeningHoursInfo\n    }\n  }\n": types.GetTribeOpeningHoursDocument,
    "\n  query getTribeHomePage($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeHomePageInfo\n    }\n  }\n": types.GetTribeHomePageDocument,
    "\n  query getTribeHomePageVideo($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeHomePageVideoInfo\n    }\n  }\n": types.GetTribeHomePageVideoDocument,
    "\n  query getTribeReviewContent($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeReviewContentInfo\n    }\n  }\n": types.GetTribeReviewContentDocument,
    "\n  query getTribeCustomComponentPage($slug: String!, $name: [String]!, $componentSlug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeCustomComponentPageInfo\n    }\n  }\n": types.GetTribeCustomComponentPageDocument,
    "\n  query getTribeCustomComponentPages($slug: String!, $name: [String]!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeCustomComponentPagesInfo\n    }\n  }\n": types.GetTribeCustomComponentPagesDocument,
    "\n  query getTribeCustomWebsitePageMenus($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      website_pages {\n        slug\n        menu_order\n        menu_label\n        show_in_menu\n      }\n    }\n  }\n": types.GetTribeCustomWebsitePageMenusDocument,
    "\n  query getTribeCustomWebsitePage($slug: String!, $websiteSlug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      website_pages(slug: $websiteSlug) {\n        slug\n        menu_label\n        show_in_menu\n        name\n        body\n        contents {\n          name\n          source\n          type\n          value\n          media_collection {\n            ...MediaCollectionInfo\n          }\n        }\n      }\n    }\n  }\n": types.GetTribeCustomWebsitePageDocument,
    "\n  query getTribeReviews($slug: String!) {\n    reviews(tribe_slug: $slug, featured: true) {\n      name\n      review\n      featured\n      business_name\n      rating\n    }\n  }\n": types.GetTribeReviewsDocument,
    "\n  query getWebsiteComponents($name: [String], $status: String) {\n    websiteComponents(name: $name, status: $status) {\n      ...ComponentTypeInfo\n      components {\n        ...ComponentInfo\n        contents {\n          ...ComponentContentInfoWithMedia\n        }\n      }\n    }\n  }\n": types.GetWebsiteComponentsDocument,
    "\n  fragment WebsiteSectionInfo on WebsiteSection {\n    name\n    contents {\n      ...WebsiteContentInfo\n    }\n  }\n": types.WebsiteSectionInfoFragmentDoc,
    "\n  fragment WebsiteContentInfo on WebsiteContent {\n    name\n    type\n    value\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n": types.WebsiteContentInfoFragmentDoc,
    "\n  query getWebsiteSection($name: [String]) {\n    websiteSections(name: $name) {\n      ...WebsiteSectionInfo\n    }\n  }\n": types.GetWebsiteSectionDocument,
    "\n  fragment WebsiteMenuInfo on WebsiteMenu {\n    name\n    menu_json\n  }\n": types.WebsiteMenuInfoFragmentDoc,
    "\n  query getWebsiteMenu($name: String!) {\n    websiteMenu(name: $name) {\n      ...WebsiteMenuInfo\n    }\n  }\n": types.GetWebsiteMenuDocument,
    "\n  query getWebsiteTribeMenu($name: String!, $tribe_slug: String!) {\n    websiteMenu(name: $name, tribe_slug: $tribe_slug) {\n      ...WebsiteMenuInfo\n    }\n  }\n": types.GetWebsiteTribeMenuDocument,
    "\n  fragment WebsitePageContentInfo on websitePageContent {\n    name\n    source\n    type\n    value\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n": types.WebsitePageContentInfoFragmentDoc,
    "\n  fragment CustomWebsitePageInfo on websitePage {\n    name\n    template_name\n    slug\n    body\n    contents {\n      ...WebsitePageContentInfo\n    }\n    products {\n      name\n      slug\n      menu_label\n      media_collection {\n        ...MediaCollectionInfo\n      }\n    }\n    productCategories {\n      name\n      slug\n      menu_label\n      media_collection {\n        ...MediaCollectionInfo\n      }\n    }\n  }\n": types.CustomWebsitePageInfoFragmentDoc,
    "\n  fragment WebsitePageInfo on websitePage {\n    name\n    slug\n    body\n    contents {\n      ...WebsitePageContentInfo\n    }\n  }\n": types.WebsitePageInfoFragmentDoc,
    "\n  fragment IndustryWithProductWebsitePageInfo on websitePage {\n    ...WebsitePageInfo\n    products {\n      name\n      slug\n      menu_label\n      heading\n      media_collection {\n        name\n        media {\n          square\n        }\n      }\n    }\n  }\n": types.IndustryWithProductWebsitePageInfoFragmentDoc,
    "\n  query getCustomWebsitePage($slug: String!) {\n    websitePage(slug: $slug) {\n      ...CustomWebsitePageInfo\n    }\n  }\n": types.GetCustomWebsitePageDocument,
    "\n  query getWebsitePage($slug: String!) {\n    websitePage(slug: $slug) {\n      ...WebsitePageInfo\n    }\n  }\n": types.GetWebsitePageDocument,
    "\n  query getTribeWebsitePage($slug: String!, $tribe_slug: String) {\n    websitePage(slug: $slug, tribe_slug: $tribe_slug) {\n      ...WebsitePageInfo\n    }\n  }\n": types.GetTribeWebsitePageDocument,
    "\n  query getTribeIndustryPage($slug: String!, $tribe_slug: String) {\n    websitePage(slug: $slug, tribe_slug: $tribe_slug) {\n      ...IndustryWithProductWebsitePageInfo\n    }\n  }\n": types.GetTribeIndustryPageDocument,
    "\n  query getWebsitePages($template_type: String!) {\n    websitePages(template_type: $template_type) {\n      ...WebsitePageInfo\n    }\n  }\n": types.GetWebsitePagesDocument,
    "\n  query getTribeWebsitePages($template_type: String!, $tribe_slug: String) {\n    websitePages(template_type: $template_type, tribe_slug: $tribe_slug) {\n      ...WebsitePageInfo\n    }\n  }\n": types.GetTribeWebsitePagesDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ArticleInfo on Article {\n    heading\n    author\n    slug\n    abstract\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n"): (typeof documents)["\n  fragment ArticleInfo on Article {\n    heading\n    author\n    slug\n    abstract\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getArticleCategories {\n    articleCategories(article_status: \"Published\") {\n      name\n      slug\n      articles_count\n    }\n  }\n"): (typeof documents)["\n  query getArticleCategories {\n    articleCategories(article_status: \"Published\") {\n      name\n      slug\n      articles_count\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getArticle($slug: String) {\n    article(slug: $slug) {\n      heading\n      sub_heading\n      author\n      slug\n      abstract\n      article\n      status\n      media_collection {\n        name\n        media {\n          square\n          banner\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getArticle($slug: String) {\n    article(slug: $slug) {\n      heading\n      sub_heading\n      author\n      slug\n      abstract\n      article\n      status\n      media_collection {\n        name\n        media {\n          square\n          banner\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getArticlesByCategories($categories: [String!], $page: Int!, $itemsPerPage: Int!) {\n    articlesPagination(\n      status: \"Published\"\n      categories: $categories\n      page: $page\n      itemsPerPage: $itemsPerPage\n    ) {\n      data {\n        ...ArticleInfo\n      }\n      total\n      has_more_pages\n      last_page\n      current_page\n    }\n  }\n"): (typeof documents)["\n  query getArticlesByCategories($categories: [String!], $page: Int!, $itemsPerPage: Int!) {\n    articlesPagination(\n      status: \"Published\"\n      categories: $categories\n      page: $page\n      itemsPerPage: $itemsPerPage\n    ) {\n      data {\n        ...ArticleInfo\n      }\n      total\n      has_more_pages\n      last_page\n      current_page\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ComponentTypeInfo on ComponentType {\n    name\n    status\n    base_path\n    components {\n      ...ComponentInfo\n    }\n  }\n"): (typeof documents)["\n  fragment ComponentTypeInfo on ComponentType {\n    name\n    status\n    base_path\n    components {\n      ...ComponentInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ComponentInfo on Component {\n    name\n    slug\n    status\n    contents {\n      ...ComponentContentInfo\n    }\n  }\n"): (typeof documents)["\n  fragment ComponentInfo on Component {\n    name\n    slug\n    status\n    contents {\n      ...ComponentContentInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ComponentContentInfoWithMedia on ComponentContent {\n    ...ComponentContentInfo\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n"): (typeof documents)["\n  fragment ComponentContentInfoWithMedia on ComponentContent {\n    ...ComponentContentInfo\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ComponentContentInfo on ComponentContent {\n    name\n    type\n    value\n  }\n"): (typeof documents)["\n  fragment ComponentContentInfo on ComponentContent {\n    name\n    type\n    value\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getfaqs {\n    websiteFaqCategory {\n      name\n      sort\n      website_faqs {\n        question\n        answer\n        slug\n        sort\n      }\n    }\n  }\n"): (typeof documents)["\n  query getfaqs {\n    websiteFaqCategory {\n      name\n      sort\n      website_faqs {\n        question\n        answer\n        slug\n        sort\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getFaqDetail($faq_slug: String!) {\n    websiteFaq(faq_slug: $faq_slug) {\n      question\n      answer\n    }\n  }\n"): (typeof documents)["\n  query getFaqDetail($faq_slug: String!) {\n    websiteFaq(faq_slug: $faq_slug) {\n      question\n      answer\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MediaCollectionInfo on MediaCollection {\n    name\n    description\n    media {\n      ...MediaInfo\n    }\n  }\n"): (typeof documents)["\n  fragment MediaCollectionInfo on MediaCollection {\n    name\n    description\n    media {\n      ...MediaInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MediaInfo on Media {\n    default\n    thumbnail\n    banner\n    square\n    rectangle\n    original_cropped\n    portrait\n    alt_tag\n    approved\n  }\n"): (typeof documents)["\n  fragment MediaInfo on Media {\n    default\n    thumbnail\n    banner\n    square\n    rectangle\n    original_cropped\n    portrait\n    alt_tag\n    approved\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SquareMediaCollection on MediaCollection {\n    name\n    media {\n      original_cropped\n      square\n    }\n  }\n"): (typeof documents)["\n  fragment SquareMediaCollection on MediaCollection {\n    name\n    media {\n      original_cropped\n      square\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BannerMediaCollection on MediaCollection {\n    name\n    media {\n      original_cropped\n      banner\n    }\n  }\n"): (typeof documents)["\n  fragment BannerMediaCollection on MediaCollection {\n    name\n    media {\n      original_cropped\n      banner\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment GalleryMedia on OriginMedia {\n    id\n    name\n    description\n    url\n    categories\n  }\n"): (typeof documents)["\n  fragment GalleryMedia on OriginMedia {\n    id\n    name\n    description\n    url\n    categories\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SlugGalleryLists($name: String!, $slug: String!) {\n    tribe(name: $name, slug: $slug) {\n      name\n      all_media(star: true, count: 300, order: \"media.created_at\", desc: true) {\n        ...GalleryMedia\n      }\n    }\n  }\n"): (typeof documents)["\n  query SlugGalleryLists($name: String!, $slug: String!) {\n    tribe(name: $name, slug: $slug) {\n      name\n      all_media(star: true, count: 300, order: \"media.created_at\", desc: true) {\n        ...GalleryMedia\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OurWorkInfo on OurWork {\n    heading\n    sub_heading\n    meta_title\n    meta_description\n    author\n    slug\n    status\n    article\n    excerpt\n    published_at\n    client_name\n    client_url\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n"): (typeof documents)["\n  fragment OurWorkInfo on OurWork {\n    heading\n    sub_heading\n    meta_title\n    meta_description\n    author\n    slug\n    status\n    article\n    excerpt\n    published_at\n    client_name\n    client_url\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getOurWorks($tribeSlug: String!, $page: Int!, $itemsPerPage: Int!) {\n    ourWorks(tribe_slug: $tribeSlug, page: $page, itemsPerPage: $itemsPerPage) {\n      data {\n        ...OurWorkInfo\n      }\n      total\n      has_more_pages\n      last_page\n      current_page\n    }\n  }\n"): (typeof documents)["\n  query getOurWorks($tribeSlug: String!, $page: Int!, $itemsPerPage: Int!) {\n    ourWorks(tribe_slug: $tribeSlug, page: $page, itemsPerPage: $itemsPerPage) {\n      data {\n        ...OurWorkInfo\n      }\n      total\n      has_more_pages\n      last_page\n      current_page\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getOurWork($slug: String!, $tribeSlug: String!) {\n    ourWork(slug: $slug, tribe_slug: $tribeSlug) {\n      ...OurWorkInfo\n    }\n  }\n"): (typeof documents)["\n  query getOurWork($slug: String!, $tribeSlug: String!) {\n    ourWork(slug: $slug, tribe_slug: $tribeSlug) {\n      ...OurWorkInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ProductCategoryInfo on ProductCategory {\n    name\n    menu_label\n    status\n    slug\n    heading\n    sub_heading\n    caption\n    path\n    description\n    meta\n    page_heading\n    page_sub_heading\n    meta_title\n    meta_description\n  }\n"): (typeof documents)["\n  fragment ProductCategoryInfo on ProductCategory {\n    name\n    menu_label\n    status\n    slug\n    heading\n    sub_heading\n    caption\n    path\n    description\n    meta\n    page_heading\n    page_sub_heading\n    meta_title\n    meta_description\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ProductInProductCategoryInfo on Product {\n    name\n    menu_label\n    slug\n    description\n    media_collection(size: \"square\") {\n      ...MediaCollectionInfo\n    }\n  }\n"): (typeof documents)["\n  fragment ProductInProductCategoryInfo on Product {\n    name\n    menu_label\n    slug\n    description\n    media_collection(size: \"square\") {\n      ...MediaCollectionInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getProductCategory($slug: String!) {\n    productCategory(slug: $slug) {\n      ...ProductCategoryInfo\n      media_collection {\n        ...BannerMediaCollection\n      }\n      children {\n        name\n        menu_label\n        slug\n        media_collection(size: \"square\") {\n          ...SquareMediaCollection\n        }\n      }\n      products {\n        ...ProductInProductCategoryInfo\n      }\n      tribe_custom_images {\n        ...BannerMediaCollection\n      }\n    }\n  }\n"): (typeof documents)["\n  query getProductCategory($slug: String!) {\n    productCategory(slug: $slug) {\n      ...ProductCategoryInfo\n      media_collection {\n        ...BannerMediaCollection\n      }\n      children {\n        name\n        menu_label\n        slug\n        media_collection(size: \"square\") {\n          ...SquareMediaCollection\n        }\n      }\n      products {\n        ...ProductInProductCategoryInfo\n      }\n      tribe_custom_images {\n        ...BannerMediaCollection\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribeProductCategory($slug: String!, $tribeSlug: String!) {\n    productCategory(slug: $slug, tribe_slug: $tribeSlug) {\n      ...ProductCategoryInfo\n      media_collection {\n        ...BannerMediaCollection\n      }\n      tribe_custom_images(images_tribe_slug: $tribeSlug) {\n        ...BannerMediaCollection\n      }\n      children {\n        name\n        menu_label\n        slug\n        media_collection(size: \"square\") {\n          ...SquareMediaCollection\n        }\n      }\n      products {\n        ...ProductInProductCategoryInfo\n      }\n      tribe_product_categories(tribe_slug: $tribeSlug) {\n        id\n        tribe_id\n        data\n      }\n    }\n  }\n"): (typeof documents)["\n  query getTribeProductCategory($slug: String!, $tribeSlug: String!) {\n    productCategory(slug: $slug, tribe_slug: $tribeSlug) {\n      ...ProductCategoryInfo\n      media_collection {\n        ...BannerMediaCollection\n      }\n      tribe_custom_images(images_tribe_slug: $tribeSlug) {\n        ...BannerMediaCollection\n      }\n      children {\n        name\n        menu_label\n        slug\n        media_collection(size: \"square\") {\n          ...SquareMediaCollection\n        }\n      }\n      products {\n        ...ProductInProductCategoryInfo\n      }\n      tribe_product_categories(tribe_slug: $tribeSlug) {\n        id\n        tribe_id\n        data\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getProductCategoryPDF($slug: String!) {\n    productCategory(slug: $slug) {\n      name\n      componentTypes(name: \"PDF Downloads\") {\n        ...ComponentTypeInfo\n        components {\n          ...ComponentInfo\n          contents {\n            ...ComponentContentInfoWithMedia\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getProductCategoryPDF($slug: String!) {\n    productCategory(slug: $slug) {\n      name\n      componentTypes(name: \"PDF Downloads\") {\n        ...ComponentTypeInfo\n        components {\n          ...ComponentInfo\n          contents {\n            ...ComponentContentInfoWithMedia\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getProductsFromProductCategories {\n    categories {\n      name\n      menu_label\n      slug\n      products {\n        ...ProductInProductCategoryInfo\n      }\n    }\n  }\n"): (typeof documents)["\n  query getProductsFromProductCategories {\n    categories {\n      name\n      menu_label\n      slug\n      products {\n        ...ProductInProductCategoryInfo\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ProductInfo on Product {\n    id\n    name\n    menu_label\n    status\n    slug\n    heading\n    sub_heading\n    caption\n    description\n    meta\n    page_heading\n    page_sub_heading\n    product_type {\n      ...ProductTypeInfo\n    }\n    product_categories {\n      ...ProductCategoryInProductInfo\n    }\n    media_collection {\n      ...MediaCollectionInfo\n    }\n    tribe_custom_images(images_tribe_slug: $tribeSlug) {\n      ...MediaCollectionInfo\n    }\n    product_custom_data(tribe_slug: $tribeSlug, website_id: $websiteId) {\n      ...ProductCustomDataInfo\n    }\n    componentTypes(name: $componentTypeName) {\n      ...ComponentTypeInfo\n    }\n  }\n"): (typeof documents)["\n  fragment ProductInfo on Product {\n    id\n    name\n    menu_label\n    status\n    slug\n    heading\n    sub_heading\n    caption\n    description\n    meta\n    page_heading\n    page_sub_heading\n    product_type {\n      ...ProductTypeInfo\n    }\n    product_categories {\n      ...ProductCategoryInProductInfo\n    }\n    media_collection {\n      ...MediaCollectionInfo\n    }\n    tribe_custom_images(images_tribe_slug: $tribeSlug) {\n      ...MediaCollectionInfo\n    }\n    product_custom_data(tribe_slug: $tribeSlug, website_id: $websiteId) {\n      ...ProductCustomDataInfo\n    }\n    componentTypes(name: $componentTypeName) {\n      ...ComponentTypeInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment productComponentTypeInfo on Component {\n    name\n    slug\n    contents {\n      name\n      type\n      value\n      media_collection {\n        name\n        media {\n          rectangle\n          banner\n        }\n      }\n    }\n    products {\n      name\n      slug\n    }\n  }\n"): (typeof documents)["\n  fragment productComponentTypeInfo on Component {\n    name\n    slug\n    contents {\n      name\n      type\n      value\n      media_collection {\n        name\n        media {\n          rectangle\n          banner\n        }\n      }\n    }\n    products {\n      name\n      slug\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ProductTypeInfo on ProductType {\n    name\n  }\n"): (typeof documents)["\n  fragment ProductTypeInfo on ProductType {\n    name\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ProductCategoryInProductInfo on ProductCategory {\n    name\n    slug\n  }\n"): (typeof documents)["\n  fragment ProductCategoryInProductInfo on ProductCategory {\n    name\n    slug\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ProductCustomDataInfo on ProductCustomData {\n    name\n    value\n    type\n  }\n"): (typeof documents)["\n  fragment ProductCustomDataInfo on ProductCustomData {\n    name\n    value\n    type\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getProduct(\n    $slug: String!\n    $websiteId: Int!\n    $componentTypeName: [String]\n    $tribeSlug: String\n  ) {\n    product(slug: $slug, tribe_slug: $tribeSlug) {\n      ...ProductInfo\n      product_related_products {\n        name\n        slug\n        media_collection {\n          name\n          media {\n            square\n            banner\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getProduct(\n    $slug: String!\n    $websiteId: Int!\n    $componentTypeName: [String]\n    $tribeSlug: String\n  ) {\n    product(slug: $slug, tribe_slug: $tribeSlug) {\n      ...ProductInfo\n      product_related_products {\n        name\n        slug\n        media_collection {\n          name\n          media {\n            square\n            banner\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getProductComponent($componentType: String!) {\n    productComponents(componentType: $componentType) {\n      ...productComponentTypeInfo\n    }\n  }\n"): (typeof documents)["\n  query getProductComponent($componentType: String!) {\n    productComponents(componentType: $componentType) {\n      ...productComponentTypeInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getProductComponentDetail($componentType: String!, $slug: String!) {\n    productComponents(componentType: $componentType, slug: $slug) {\n      ...productComponentTypeInfo\n    }\n  }\n"): (typeof documents)["\n  query getProductComponentDetail($componentType: String!, $slug: String!) {\n    productComponents(componentType: $componentType, slug: $slug) {\n      ...productComponentTypeInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PromotionWebsiteContentInfo on PromotionWebsiteContent {\n    name\n    type\n    value\n    products\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n"): (typeof documents)["\n  fragment PromotionWebsiteContentInfo on PromotionWebsiteContent {\n    name\n    type\n    value\n    products\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PromotionInfo on Promotion {\n    name\n    slug\n    type\n    status\n    fields\n    start_date\n    end_date\n    website_content {\n      ...PromotionWebsiteContentInfo\n    }\n  }\n"): (typeof documents)["\n  fragment PromotionInfo on Promotion {\n    name\n    slug\n    type\n    status\n    fields\n    start_date\n    end_date\n    website_content {\n      ...PromotionWebsiteContentInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PromotionBannerInfo on PromotionBanner {\n    name\n    value\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n"): (typeof documents)["\n  fragment PromotionBannerInfo on PromotionBanner {\n    name\n    value\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getLivePromotionBanner {\n    promotionBanner {\n      name\n      start_date\n      end_date\n      banner {\n        ...PromotionBannerInfo\n      }\n    }\n  }\n"): (typeof documents)["\n  query getLivePromotionBanner {\n    promotionBanner {\n      name\n      start_date\n      end_date\n      banner {\n        ...PromotionBannerInfo\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPromotion($slug: String!) {\n    promotion(slug: $slug) {\n      ...PromotionInfo\n    }\n  }\n"): (typeof documents)["\n  query getPromotion($slug: String!) {\n    promotion(slug: $slug) {\n      ...PromotionInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPromotionVueComponent($slug: String!) {\n    promotion(slug: $slug) {\n      name\n      slug\n      vue_component\n    }\n  }\n"): (typeof documents)["\n  query getPromotionVueComponent($slug: String!) {\n    promotion(slug: $slug) {\n      name\n      slug\n      vue_component\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getRegionPolygons($slug: String, $countryISO: String, $regionType: String) {\n      regions(slug: $slug, country_iso: $countryISO, type: $regionType) {\n        name\n        country_iso\n        name_abbreviated\n        latitude\n        longitude\n        zoom\n        polygons\n      }\n    }\n  "): (typeof documents)["\n    query getRegionPolygons($slug: String, $countryISO: String, $regionType: String) {\n      regions(slug: $slug, country_iso: $countryISO, type: $regionType) {\n        name\n        country_iso\n        name_abbreviated\n        latitude\n        longitude\n        zoom\n        polygons\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BaseTribeInfo on Tribe {\n    name\n    slug\n    heading\n    sub_heading\n    country\n    postal_code\n    state\n    state_abbreviated\n    locality\n    address_1\n    address_2\n    latitude\n    longitude\n    main_telephone\n    organic_number\n    paid_number\n    telephone_url_triggers\n    public_email\n    google_place_id\n    social_google_url\n    social_twitter_url\n    social_facebook_url\n    social_linkedin_url\n    social_instagram_url\n    social_youtube_url\n    social_pinterest_url\n    facebook_review_link\n    google_review_url\n    yelp_review_url\n    meta_description\n    meta_title\n    whats_app_number\n    facebook_pixel_id\n  }\n"): (typeof documents)["\n  fragment BaseTribeInfo on Tribe {\n    name\n    slug\n    heading\n    sub_heading\n    country\n    postal_code\n    state\n    state_abbreviated\n    locality\n    address_1\n    address_2\n    latitude\n    longitude\n    main_telephone\n    organic_number\n    paid_number\n    telephone_url_triggers\n    public_email\n    google_place_id\n    social_google_url\n    social_twitter_url\n    social_facebook_url\n    social_linkedin_url\n    social_instagram_url\n    social_youtube_url\n    social_pinterest_url\n    facebook_review_link\n    google_review_url\n    yelp_review_url\n    meta_description\n    meta_title\n    whats_app_number\n    facebook_pixel_id\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TribeInfo on Tribe {\n    ...BaseTribeInfo\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n"): (typeof documents)["\n  fragment TribeInfo on Tribe {\n    ...BaseTribeInfo\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TribeTypeInfo on TribeType {\n    name\n  }\n"): (typeof documents)["\n  fragment TribeTypeInfo on TribeType {\n    name\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TribeContentInfo on TribeContent {\n    name\n    value\n    type\n  }\n"): (typeof documents)["\n  fragment TribeContentInfo on TribeContent {\n    name\n    value\n    type\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TribeMenuInfo on TribeMenu {\n    name\n    data\n  }\n"): (typeof documents)["\n  fragment TribeMenuInfo on TribeMenu {\n    name\n    data\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TribeLicenseInfo on Tribe {\n    ...TribeInfo\n    contents(name: \"Licenses\") {\n      ...TribeContentInfo\n    }\n  }\n"): (typeof documents)["\n  fragment TribeLicenseInfo on Tribe {\n    ...TribeInfo\n    contents(name: \"Licenses\") {\n      ...TribeContentInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TribeTeamMembersInfo on Tribe {\n    name\n    introduction_team\n    teamMembers(active: true) {\n      first_name\n      last_name\n      about\n      role\n      avatar\n      email\n      show_on_website\n      mobile_e164\n      email_public\n      mobile_public\n      telephone_public\n      telephone_number\n    }\n    contents {\n      ...TribeContentInfo\n      media_collection {\n        ...MediaCollectionInfo\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment TribeTeamMembersInfo on Tribe {\n    name\n    introduction_team\n    teamMembers(active: true) {\n      first_name\n      last_name\n      about\n      role\n      avatar\n      email\n      show_on_website\n      mobile_e164\n      email_public\n      mobile_public\n      telephone_public\n      telephone_number\n    }\n    contents {\n      ...TribeContentInfo\n      media_collection {\n        ...MediaCollectionInfo\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TribeContactInfo on Tribe {\n    name\n    contents(names: [\"Contact Page Message\"]) {\n      ...TribeContentInfo\n    }\n  }\n"): (typeof documents)["\n  fragment TribeContactInfo on Tribe {\n    name\n    contents(names: [\"Contact Page Message\"]) {\n      ...TribeContentInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TribeOpeningHoursInfo on Tribe {\n    show_opening_hours\n    tribe_opening_hours\n    opening_hours_message\n  }\n"): (typeof documents)["\n  fragment TribeOpeningHoursInfo on Tribe {\n    show_opening_hours\n    tribe_opening_hours\n    opening_hours_message\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TribeHomePageInfo on Tribe {\n    name\n    page_heading\n    page_sub_heading\n    introduction_bold\n    introduction\n    media_collection {\n      ...MediaCollectionInfo\n    }\n    contents(names: [\"Accolades\", \"Second Phone Number\", \"Homepage Video\"]) {\n      ...TribeContentInfo\n      media_collection {\n        ...MediaCollectionInfo\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment TribeHomePageInfo on Tribe {\n    name\n    page_heading\n    page_sub_heading\n    introduction_bold\n    introduction\n    media_collection {\n      ...MediaCollectionInfo\n    }\n    contents(names: [\"Accolades\", \"Second Phone Number\", \"Homepage Video\"]) {\n      ...TribeContentInfo\n      media_collection {\n        ...MediaCollectionInfo\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TribeHomePageVideoInfo on Tribe {\n    name\n    contents(names: [\"Homepage Video\"]) {\n      ...TribeContentInfo\n    }\n  }\n"): (typeof documents)["\n  fragment TribeHomePageVideoInfo on Tribe {\n    name\n    contents(names: [\"Homepage Video\"]) {\n      ...TribeContentInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TribeReviewContentInfo on Tribe {\n    name\n    contents(names: [\"Review Caption\"]) {\n      ...TribeContentInfo\n    }\n  }\n"): (typeof documents)["\n  fragment TribeReviewContentInfo on Tribe {\n    name\n    contents(names: [\"Review Caption\"]) {\n      ...TribeContentInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TribeCustomComponentInfo on TribeComponent {\n    name\n    slug\n    contents {\n      value\n      name\n      type\n    }\n  }\n"): (typeof documents)["\n  fragment TribeCustomComponentInfo on TribeComponent {\n    name\n    slug\n    contents {\n      value\n      name\n      type\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TribeCustomComponentPageInfo on Tribe {\n    component_types(componentTypeName: $name) {\n      name\n      status\n      components(slug: $componentSlug) {\n        ...TribeCustomComponentInfo\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment TribeCustomComponentPageInfo on Tribe {\n    component_types(componentTypeName: $name) {\n      name\n      status\n      components(slug: $componentSlug) {\n        ...TribeCustomComponentInfo\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TribeCustomComponentPagesInfo on Tribe {\n    component_types(componentTypeName: $name) {\n      name\n      status\n      components {\n        name\n        slug\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment TribeCustomComponentPagesInfo on Tribe {\n    component_types(componentTypeName: $name) {\n      name\n      status\n      components {\n        name\n        slug\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribes($tribeType: String, $order: [String]) {\n    tribes(name: $tribeType, orderBy: $order) {\n      ...TribeInfo\n    }\n  }\n"): (typeof documents)["\n  query getTribes($tribeType: String, $order: [String]) {\n    tribes(name: $tribeType, orderBy: $order) {\n      ...TribeInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribePromotionBanner($slug: String!, $status: String) {\n    tribe(slug: $slug, status: $status) {\n      contents(names: [\"Promotion Banner\"]) {\n        ...TribeContentInfo\n      }\n    }\n  }\n"): (typeof documents)["\n  query getTribePromotionBanner($slug: String!, $status: String) {\n    tribe(slug: $slug, status: $status) {\n      contents(names: [\"Promotion Banner\"]) {\n        ...TribeContentInfo\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribe($slug: String!, $status: String) {\n    tribe(slug: $slug, status: $status) {\n      ...TribeInfo\n      media_collection {\n        ...MediaCollectionInfo\n      }\n    }\n  }\n"): (typeof documents)["\n  query getTribe($slug: String!, $status: String) {\n    tribe(slug: $slug, status: $status) {\n      ...TribeInfo\n      media_collection {\n        ...MediaCollectionInfo\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribeMenus($slug: String!, $status: String, $tribeType: String, $tribeMenus: [String]) {\n    tribe(slug: $slug, status: $status) {\n      tribe_menus(tribe_type: $tribeType, menus: $tribeMenus) {\n        ...TribeMenuInfo\n      }\n    }\n  }\n"): (typeof documents)["\n  query getTribeMenus($slug: String!, $status: String, $tribeType: String, $tribeMenus: [String]) {\n    tribe(slug: $slug, status: $status) {\n      tribe_menus(tribe_type: $tribeType, menus: $tribeMenus) {\n        ...TribeMenuInfo\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query searchStores(\n    $tribe_type: String!\n    $address: String!\n    $lat: Float!\n    $lng: Float!\n    $radius: Float!\n  ) {\n    searchStores(\n      tribe_type: $tribe_type\n      address: $address\n      lat: $lat\n      lng: $lng\n      radius: $radius\n    ) {\n      tribe_types {\n        ...TribeTypeInfo\n      }\n      contents(name: \"External URL\") {\n        ...TribeContentInfo\n      }\n      ...BaseTribeInfo\n    }\n  }\n"): (typeof documents)["\n  query searchStores(\n    $tribe_type: String!\n    $address: String!\n    $lat: Float!\n    $lng: Float!\n    $radius: Float!\n  ) {\n    searchStores(\n      tribe_type: $tribe_type\n      address: $address\n      lat: $lat\n      lng: $lng\n      radius: $radius\n    ) {\n      tribe_types {\n        ...TribeTypeInfo\n      }\n      contents(name: \"External URL\") {\n        ...TribeContentInfo\n      }\n      ...BaseTribeInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribeLicense($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeLicenseInfo\n    }\n  }\n"): (typeof documents)["\n  query getTribeLicense($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeLicenseInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribeTeamMembers($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeTeamMembersInfo\n    }\n  }\n"): (typeof documents)["\n  query getTribeTeamMembers($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeTeamMembersInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribeContact($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeContactInfo\n    }\n  }\n"): (typeof documents)["\n  query getTribeContact($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeContactInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribeOpeningHours($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeOpeningHoursInfo\n    }\n  }\n"): (typeof documents)["\n  query getTribeOpeningHours($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeOpeningHoursInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribeHomePage($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeHomePageInfo\n    }\n  }\n"): (typeof documents)["\n  query getTribeHomePage($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeHomePageInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribeHomePageVideo($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeHomePageVideoInfo\n    }\n  }\n"): (typeof documents)["\n  query getTribeHomePageVideo($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeHomePageVideoInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribeReviewContent($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeReviewContentInfo\n    }\n  }\n"): (typeof documents)["\n  query getTribeReviewContent($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeReviewContentInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribeCustomComponentPage($slug: String!, $name: [String]!, $componentSlug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeCustomComponentPageInfo\n    }\n  }\n"): (typeof documents)["\n  query getTribeCustomComponentPage($slug: String!, $name: [String]!, $componentSlug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeCustomComponentPageInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribeCustomComponentPages($slug: String!, $name: [String]!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeCustomComponentPagesInfo\n    }\n  }\n"): (typeof documents)["\n  query getTribeCustomComponentPages($slug: String!, $name: [String]!) {\n    tribe(slug: $slug, status: \"all\") {\n      ...TribeCustomComponentPagesInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribeCustomWebsitePageMenus($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      website_pages {\n        slug\n        menu_order\n        menu_label\n        show_in_menu\n      }\n    }\n  }\n"): (typeof documents)["\n  query getTribeCustomWebsitePageMenus($slug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      website_pages {\n        slug\n        menu_order\n        menu_label\n        show_in_menu\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribeCustomWebsitePage($slug: String!, $websiteSlug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      website_pages(slug: $websiteSlug) {\n        slug\n        menu_label\n        show_in_menu\n        name\n        body\n        contents {\n          name\n          source\n          type\n          value\n          media_collection {\n            ...MediaCollectionInfo\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getTribeCustomWebsitePage($slug: String!, $websiteSlug: String!) {\n    tribe(slug: $slug, status: \"all\") {\n      website_pages(slug: $websiteSlug) {\n        slug\n        menu_label\n        show_in_menu\n        name\n        body\n        contents {\n          name\n          source\n          type\n          value\n          media_collection {\n            ...MediaCollectionInfo\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribeReviews($slug: String!) {\n    reviews(tribe_slug: $slug, featured: true) {\n      name\n      review\n      featured\n      business_name\n      rating\n    }\n  }\n"): (typeof documents)["\n  query getTribeReviews($slug: String!) {\n    reviews(tribe_slug: $slug, featured: true) {\n      name\n      review\n      featured\n      business_name\n      rating\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getWebsiteComponents($name: [String], $status: String) {\n    websiteComponents(name: $name, status: $status) {\n      ...ComponentTypeInfo\n      components {\n        ...ComponentInfo\n        contents {\n          ...ComponentContentInfoWithMedia\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getWebsiteComponents($name: [String], $status: String) {\n    websiteComponents(name: $name, status: $status) {\n      ...ComponentTypeInfo\n      components {\n        ...ComponentInfo\n        contents {\n          ...ComponentContentInfoWithMedia\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WebsiteSectionInfo on WebsiteSection {\n    name\n    contents {\n      ...WebsiteContentInfo\n    }\n  }\n"): (typeof documents)["\n  fragment WebsiteSectionInfo on WebsiteSection {\n    name\n    contents {\n      ...WebsiteContentInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WebsiteContentInfo on WebsiteContent {\n    name\n    type\n    value\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n"): (typeof documents)["\n  fragment WebsiteContentInfo on WebsiteContent {\n    name\n    type\n    value\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getWebsiteSection($name: [String]) {\n    websiteSections(name: $name) {\n      ...WebsiteSectionInfo\n    }\n  }\n"): (typeof documents)["\n  query getWebsiteSection($name: [String]) {\n    websiteSections(name: $name) {\n      ...WebsiteSectionInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WebsiteMenuInfo on WebsiteMenu {\n    name\n    menu_json\n  }\n"): (typeof documents)["\n  fragment WebsiteMenuInfo on WebsiteMenu {\n    name\n    menu_json\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getWebsiteMenu($name: String!) {\n    websiteMenu(name: $name) {\n      ...WebsiteMenuInfo\n    }\n  }\n"): (typeof documents)["\n  query getWebsiteMenu($name: String!) {\n    websiteMenu(name: $name) {\n      ...WebsiteMenuInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getWebsiteTribeMenu($name: String!, $tribe_slug: String!) {\n    websiteMenu(name: $name, tribe_slug: $tribe_slug) {\n      ...WebsiteMenuInfo\n    }\n  }\n"): (typeof documents)["\n  query getWebsiteTribeMenu($name: String!, $tribe_slug: String!) {\n    websiteMenu(name: $name, tribe_slug: $tribe_slug) {\n      ...WebsiteMenuInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WebsitePageContentInfo on websitePageContent {\n    name\n    source\n    type\n    value\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n"): (typeof documents)["\n  fragment WebsitePageContentInfo on websitePageContent {\n    name\n    source\n    type\n    value\n    media_collection {\n      ...MediaCollectionInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CustomWebsitePageInfo on websitePage {\n    name\n    template_name\n    slug\n    body\n    contents {\n      ...WebsitePageContentInfo\n    }\n    products {\n      name\n      slug\n      menu_label\n      media_collection {\n        ...MediaCollectionInfo\n      }\n    }\n    productCategories {\n      name\n      slug\n      menu_label\n      media_collection {\n        ...MediaCollectionInfo\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CustomWebsitePageInfo on websitePage {\n    name\n    template_name\n    slug\n    body\n    contents {\n      ...WebsitePageContentInfo\n    }\n    products {\n      name\n      slug\n      menu_label\n      media_collection {\n        ...MediaCollectionInfo\n      }\n    }\n    productCategories {\n      name\n      slug\n      menu_label\n      media_collection {\n        ...MediaCollectionInfo\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WebsitePageInfo on websitePage {\n    name\n    slug\n    body\n    contents {\n      ...WebsitePageContentInfo\n    }\n  }\n"): (typeof documents)["\n  fragment WebsitePageInfo on websitePage {\n    name\n    slug\n    body\n    contents {\n      ...WebsitePageContentInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment IndustryWithProductWebsitePageInfo on websitePage {\n    ...WebsitePageInfo\n    products {\n      name\n      slug\n      menu_label\n      heading\n      media_collection {\n        name\n        media {\n          square\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment IndustryWithProductWebsitePageInfo on websitePage {\n    ...WebsitePageInfo\n    products {\n      name\n      slug\n      menu_label\n      heading\n      media_collection {\n        name\n        media {\n          square\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getCustomWebsitePage($slug: String!) {\n    websitePage(slug: $slug) {\n      ...CustomWebsitePageInfo\n    }\n  }\n"): (typeof documents)["\n  query getCustomWebsitePage($slug: String!) {\n    websitePage(slug: $slug) {\n      ...CustomWebsitePageInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getWebsitePage($slug: String!) {\n    websitePage(slug: $slug) {\n      ...WebsitePageInfo\n    }\n  }\n"): (typeof documents)["\n  query getWebsitePage($slug: String!) {\n    websitePage(slug: $slug) {\n      ...WebsitePageInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribeWebsitePage($slug: String!, $tribe_slug: String) {\n    websitePage(slug: $slug, tribe_slug: $tribe_slug) {\n      ...WebsitePageInfo\n    }\n  }\n"): (typeof documents)["\n  query getTribeWebsitePage($slug: String!, $tribe_slug: String) {\n    websitePage(slug: $slug, tribe_slug: $tribe_slug) {\n      ...WebsitePageInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribeIndustryPage($slug: String!, $tribe_slug: String) {\n    websitePage(slug: $slug, tribe_slug: $tribe_slug) {\n      ...IndustryWithProductWebsitePageInfo\n    }\n  }\n"): (typeof documents)["\n  query getTribeIndustryPage($slug: String!, $tribe_slug: String) {\n    websitePage(slug: $slug, tribe_slug: $tribe_slug) {\n      ...IndustryWithProductWebsitePageInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getWebsitePages($template_type: String!) {\n    websitePages(template_type: $template_type) {\n      ...WebsitePageInfo\n    }\n  }\n"): (typeof documents)["\n  query getWebsitePages($template_type: String!) {\n    websitePages(template_type: $template_type) {\n      ...WebsitePageInfo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTribeWebsitePages($template_type: String!, $tribe_slug: String) {\n    websitePages(template_type: $template_type, tribe_slug: $tribe_slug) {\n      ...WebsitePageInfo\n    }\n  }\n"): (typeof documents)["\n  query getTribeWebsitePages($template_type: String!, $tribe_slug: String) {\n    websitePages(template_type: $template_type, tribe_slug: $tribe_slug) {\n      ...WebsitePageInfo\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;